import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Container, Box, Divider, Paper } from "@material-ui/core"
import "../styles/general.css"
import Footer from "./Footer"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import FavoriteIcon from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MoreVertIcon from "@material-ui/icons/MoreVert"

import ImgUnisabanaH from "../components/imgComponents/imgUnisabanaH"
import ImgHospitalKennedy from "./imgComponents/imgHospitalKennedy"
import ImgHospitalTunal from "./imgComponents/imgHospitalTunal"
import ImgHospitalSanBlas from "./imgComponents/imgHospitalSanBlas"
import ImgHospitalSanRafaelFaca from "./imgComponents/imgHospitalSanRafaelFaca"
import ImgHospitalSamaritanaZipa from "./imgComponents/imgHospitalSamaritanaZipa"
import ImgHospitalTunjuelito from "./imgComponents/imgHospitalTunjuelito"
import ImgSecretariaSalud from "./imgComponents/imgSecretariaSalud"
import ImgHospitalSanRafaelFusa from "./imgComponents/imgHospitalSanRafaelFusa"
import ImgClinicaShaio from "./imgComponents/imgClinicaShaio"
import ImgInc from "./imgComponents/imgInc"
import ImgClinicaLaSabana from "./imgComponents/imgClinicaLaSabana"
import ImgAmiPallium from "./imgComponents/imgAmiPallium"
import ImgBienestarIps from "./imgComponents/imgBienestarIps"
import ImgCardioInfantilsq from "./imgComponents/imgCardioInfantilsq"
import ImgChangePainsq from "./imgComponents/imgChangePainsq"
import ImgEcancersq from "./imgComponents/imgEcancersq"
import ImgAsocupac from "./imgComponents/imgAsocupac"
import ImgValleDelLili from "./imgComponents/imgValleDelLili"
import ImgClinicaDelNorte from "./imgComponents/imgClinicaDelNorte"
import ImgScare from "./imgComponents/imgScare"
import ImgCruzRoja from "./imgComponents/imgCruzRoja"
import ImgAcpp from "./imgComponents/imgAcpp"
import ImgSnt from "./imgComponents/imgSnt"
import ImgAccp from "./imgComponents/imgAccp"
import ImgCmc from "./imgComponents/imgCmc"
import ImgAcp from "./imgComponents/imgAcp"

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    // height: 0,
    // paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    // transform: "rotate(180deg)",
  },
}))

const AssociationsComponent = () => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()

  return (
    <div className="componentsDiv">
      <Box align="justify">
        <h1 className="titlesGradientH1">{t("title.associations")}</h1>
        <Divider />
        <br />
        <Card className={classes.root} variant="outlined" square>
          <CardHeader avatar={<ImgAccp />} title={t("associations.accp")} />
        </Card>
        <br />
        <Card className={classes.root} variant="outlined" square>
          <CardHeader avatar={<ImgAsocupac />} title={t("associations.acpc")} />
        </Card>
        <br />
        <Card className={classes.root} variant="outlined" square>
          <CardHeader avatar={<ImgCmc />} title={t("associations.cmc")} />
        </Card>
        <br />
        <Card className={classes.root} variant="outlined" square>
          <CardHeader avatar={<ImgAcp />} title={t("associations.acp")} />
        </Card>
        <br />
      </Box>
      <br />
      <Box>
        <Footer />
      </Box>
    </div>
  )
}

export default AssociationsComponent
